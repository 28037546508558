<!-- 地图坐标点列表 -->
<template>
    <div class='mapBox'>
        <div class="header">
            <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png"/>
            <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
            <span class="header-txt">智惠妇儿</span>
            <!-- 关闭 刷新 -->
            <div class="idx-middlesx-box3">
                <div class="idx-middle-1">
                    <img class="fh" src="@/assets/image/zhfe/return.png" @click="goBack"/>
                    <img class="sx" src="@/assets/image/zhfe/icon/sx.png" alt="" @click="refresh" />
                </div>
            </div>
        </div>
        <!-- 时间 -->
        <div class="time-box">
            <Date></Date>
        </div>
        <div class="page_title">首页地图管理</div>
        <div class="content">
            <div class="map-seach">
                <div class="seach-left">
                    <div class="seach-page">
                        <el-input v-model="queryParam.remark" placeholder="请输入名称/简介搜索"></el-input>
                    </div>
                    <div class="seach-page">
                        <el-select v-model="queryParam.type" placeholder="请选择要搜索的内容" @change="selectChange">
                            <el-option
                                v-for="(item, index) in maptypeList"
                                :key="index"
                                :label="item.dataName"
                                :value="item.dataDetailed"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="btn_box">
                        <div class="query_btn" @click="onQuery">查询</div>
                        <div class="query_btn" @click="onReset">重置</div>
                    </div>
                </div>
                <div class="seach-right">
                    <div class="query_btn" @click="addMap">新增</div>
                </div>
            </div>
            <div class="map-table">
                <el-table
                    :data="tableData"
                    height="100%"
                    row-class-name="rowName"
                    style="width: 100%">
                        <el-table-column type="index" label="序号" :width="isTablet?50:100"></el-table-column>
                        <el-table-column prop="typeName" label="类别" :width="isTablet?90:180"></el-table-column>
                        <el-table-column prop="mapName" label="名称" :width="isTablet?120:220"></el-table-column>
                        <el-table-column prop="longitude" label="经度" :width="isTablet?90:180"></el-table-column>
                        <el-table-column prop="latitude" label="纬度" :width="isTablet?90:180"></el-table-column>
                        <el-table-column prop="remark" label="简介" ></el-table-column>
                        <el-table-column label="图片" :width="isTablet?50:100">
                            <template slot-scope="scope">
                                <el-button @click="lookMap(scope.row)" type="text" size="small">查看</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" :width="isTablet?120:180">
                            <template slot-scope="scope">
                                <el-button @click="updateMap(scope.row)" type="text" size="small"><i class="el-icon-edit"></i> 修改</el-button>
                                <el-button @click="delMap(scope.row)" type="text" size="small"><i class="el-icon-delete"></i> 删除</el-button>
                            </template>
                        </el-table-column>
                </el-table>
            </div>
            <div class="pagination_box">
                <el-pagination
                    :page-size="queryParam.pageSize"
                    :current-page="queryParam.pageNum"
                    background
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                    @current-change="changeNum"
                    @size-change="changeSize"
                >
                </el-pagination>
            </div>
        </div>
        <!-- 图片查看 -->
        <el-dialog
            :visible.sync="imgshow"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="51vw"
            center
        >
            <div class="dialogTitleBox">
                <div class="dialogTitle">图片查看</div>
                <div class="dialogClose" @click="imgshow=false"></div>
            </div>
            <div class="dialogMainImg">
                <div v-for="(item, i) in imgList" :key="i" class="imgbox">
                    <img :src="item" />
                </div>
            </div>
        </el-dialog>
        <!-- 新增 -->
        <el-dialog
            :visible.sync="addMapshow"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="50vw"
            center
        >
            <div class="dialogTitleBox">
                <div class="dialogTitle">{{mapTitle}}</div>
                <div class="dialogClose" @click="closeDialog"></div>
            </div>
            <div class="mapdialogBox">
                <div class="map-item">
                    <div class="map-label"><span>*</span>名称</div>
                    <div class="map-input"><input type="text" v-model="updateMapData.name" ></div>
                </div>
                <div class="map-item">
                    <div class="map-label"><span>*</span>经度</div>
                    <div class="map-input"><input type="text" v-model="updateMapData.longitude" ></div>
                </div>
                <div class="map-item">
                    <div class="map-label"><span>*</span>类别</div>
                    <div class="map-input">
                        <el-select v-model="updateMapData.type" placeholder=""  class="select">
                            <el-option
                                v-for="(item, index) in maptypeList"
                                :key="index"
                                :label="item.dataName"
                                :value="item.dataDetailed"
                            ></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="map-item">
                    <div class="map-label"><span>*</span>纬度</div>
                    <div class="map-input"><input type="text" v-model="updateMapData.latitude" ></div>
                </div>
                <div class="map-item textarea">
                    <div class="map-label">简介</div>
                    <textarea class="map-textarea" v-model="updateMapData.remark"></textarea>
                </div>
                <div class="map-item textarea">
                    <div class="map-label">图片</div>
                    <el-upload
                        class="avatar-uploader"
                        :action="uploadAction"
                        :limit="10"
                        list-type="picture-card"
                        :file-list="uploadImglist"
                        :on-remove="handleRemove"
                        :on-exceed="handleExceed"
                        :on-error="imgerror"
                        :on-success="imgsuccess"
                        :before-upload="beforeAvatarUpload">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div class="uploadTips">只能上传JPG/PNG格式文件，最多只能上传10张图片，单个图片不能超过150kb.</div>
                <div class="dialog_btn" style="display: block">
                    <div style="margin-right: 50px" @click="closeDialog">取消</div>
                    <div @click="onSubmit">确定</div>
                </div>
            </div>
            
        </el-dialog>
        <!-- 删除 -->
        <dialogAlert :dialogVisible="dialogVisible" :dialogData="dialogData" @closeDialog="closeDialogDel" @determine="determine">
            <span slot="txt">{{delDataTxt}}</span>
        </dialogAlert>
    </div>
</template>

<script>
import Date from '@/views/commonComponents/date.vue';
import {listData } from '@/api/dtdata.js';
import { listMap, addMap, updateMap, delMap } from "@/api/map";
import dialogAlert from '@/views/homeImg/components/dialogAlert.vue';
export default {
    components: {
        Date,
        dialogAlert
    },
    data() {
        return {
            tableData: [],
            queryParam:{
                remark:'',
                type: '',
                pageSize: 10,
                pageNum: 1
            },
            total:0,
            typeList:[],
            maptypeList: [],//地图类别
            loading:false,
            imgshow:false,//图片显示
            imgList:[],//图片列表
            addMapshow:false,//新增修改坐标
            mapTitle:'',
            uploadAction: '/prod-api/common/upload',//图片上传地址
            // imageUrl: '',
            uploadImglist:[],// 上传的图片
            updateMapData:{
                name:'',
                type:'',
                longitude:'',
                latitude:'',
                remark:'',
                mapId: ''
            },
            isupdate: false,
            dialogVisible: false,// 删除的弹窗
            dialogData: '',// 删除的数据
            delDataTxt: '',//删除的提示
            isTablet: false
        };
    },
    methods: {
        // 关闭弹窗
        closeDialog(){
            this.addMapshow=false
            this.updateMapData.latitude = ''
            this.updateMapData.longitude = ''
            this.updateMapData.name = ''
            this.updateMapData.remark = ''
            this.updateMapData.mapId = ''
            this.updateMapData.type = ''
            this.uploadImglist = []
        },
        selectChange(e){
            this.queryParam.pageNum = 1
            this.onQuery()
        },
        // 地图类别列表
        async getMaptypeList(){
            await listData({
                dataModule:"sysj",
                dataTyp: 'sysj-map'
            }).then(res=>{
                if(res.code == 200){
                    this.$nextTick(()=>{
                        this.maptypeList = res.rows
                        this.queryParam.type = res.rows[0].dataDetailed
                        this.onQuery()
                    })
                }
            })
        },
        // 新增
        addMap(){
            let islogin = this.islogin()
            if(islogin == 1){
                this.mapTitle = '新增首页地图'
                this.uploadImglist = []
                this.addMapshow = true
                this.isupdate = false
            }
        },
        // 修改
        updateMap(data){
            let islogin = this.islogin()
            if(islogin == 1){
                this.uploadImglist = []
                this.mapTitle = '修改首页地图'
                let lodings = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.imgList = []
                listData({
                    dataModule:"symap",
                    dataTyp: 'symap-'+data.mapTyp + '-' + data.mapId
                }).then(res=>{
                    if(res.code == 200){
                        this.isupdate = true
                        this.updateMapData.name= data.mapName
                        this.updateMapData.longitude= data.longitude
                        this.updateMapData.latitude= data.latitude
                        this.updateMapData.remark= data.remark
                        this.updateMapData.type= data.mapTyp
                        this.updateMapData.mapId = data.mapId
                        res.rows.forEach(item=>{
                            this.uploadImglist.push({
                                id:item.mapId,
                                url:item.dataDetailed
                            })
                        })
                        this.addMapshow = true
                    }
                }).finally(()=>{
                    lodings.close();
                });
            }
            
            
        },
        closeDialogDel(){
            this.dialogVisible = false
        },
        determine(datas){
            delMap(datas.mapId).then(res=>{
                if(res.code == 200){
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.queryParam.pageNum = 1
                    this.onQuery()
                }
            }).finally(()=>{
                this.dialogVisible = false
            })
        },
        // 删除
        delMap(data){
            let islogin = this.islogin()
            if(islogin == 1){
                this.dialogVisible = true
                this.delDataTxt = '您确定要删除 '+data.mapName+' 吗?'
                this.dialogData = data
            }
        },
        // 查看图片
        lookMap(data){
            let islogin = this.islogin()
            if(islogin > 0){
                let lodings = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.imgList = []
                listData({
                    dataModule:"symap",
                    dataTyp: 'symap-'+data.mapTyp + '-' + data.mapId
                }).then(res=>{
                    if(res.code == 200){
                        this.imgshow = true
                        res.rows.forEach(item=>{
                            this.imgList.push(item.dataDetailed)
                        })
                    }
                }).finally(()=>{
                    lodings.close();
                });
            }
        },
        //查询
        onQuery(){
            this.loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.tableData = []
            listMap({
                mapName:this.queryParam.remark,
                mapTyp: this.queryParam.type,
                pageSize: this.queryParam.pageSize,
                pageNum: this.queryParam.pageNum
             }).then(res=>{
                res.rows.forEach(item => {
                    this.maptypeList.forEach(typeitem=>{
                        if(typeitem.dataDetailed == item.mapTyp){
                            item.typeName = typeitem.dataName
                        }
                    })
                    this.tableData.push(item)
                });
                this.total = res.total
            }).finally(()=>{
                this.loading.close();
            })
        },
        // 图片新增修改确认
        onSubmit(){
            if(!this.updateMapData.name.trim()){
                this.$message.warning('请输入名称')
            }else if(!this.updateMapData.longitude.trim()){
                this.$message.warning('请输入经度')
            }else if(!this.updateMapData.latitude.trim()){
                this.$message.warning('请输入纬度')
            }else if(!this.updateMapData.type.trim()){
                this.$message.warning('请选择类别')
            }else{
                this.loading = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if(this.isupdate){
                    let imgData = []
                    this.uploadImglist.forEach(item=>{
                        imgData.push(item.url)
                    })
                    updateMap({
                        mapName: this.updateMapData.name,//名称
                        longitude: this.updateMapData.longitude,//经度
                        latitude:this.updateMapData.latitude,//纬度
                        remark:this.updateMapData.remark,//简介
                        mapTyp: this.updateMapData.type,//类别
                        pics: imgData,//图片
                        mapId: this.updateMapData.mapId
                    }).then(res=>{
                        if(res.code == 200){
                            this.$message.success('修改成功')
                            this.closeDialog()
                            this.queryParam.pageNum = 1
                            this.onQuery()
                        }
                    }).finally(()=>{
                        this.loading.close()
                    })
                }else{
                    let newMappics = []
                    this.uploadImglist.forEach(item=>{
                        newMappics.push(item.url)
                    })
                    addMap({
                        mapName: this.updateMapData.name,//名称
                        longitude: this.updateMapData.longitude,//经度
                        latitude:this.updateMapData.latitude,//纬度
                        remark:this.updateMapData.remark,//简介
                        mapTyp: this.updateMapData.type,//类别
                        pics: newMappics,//图片
                    }).then(res=>{
                        if(res.code == 200){
                            this.$message.success('添加成功')
                            this.closeDialog()
                            this.queryParam.pageNum = 1
                            this.onQuery()
                        }
                    }).finally(()=>{
                        this.loading.close()
                    })
                }
            }
        },
        changeNum(e){
            this.queryParam.pageNum = e
            this.onQuery()
        },
        changeSize(e){
            this.queryParam.pageSize = e
            this.queryParam.pageNum = 1
            this.onQuery()
        },
        // 重置
        onReset() {
            this.queryParam.remark = ''
            this.queryParam.type = this.maptypeList[0].dataDetailed
            this.onQuery()
        },
        refresh() {
            this.$router.go(0); //刷新当前页面
        },
        goBack() {
            this.$router.go(-1);
        },
        // 图片上传成功
        imgsuccess(res, file){
            if(res.code == 200){
                // this.imageUrl = URL.createObjectURL(file.raw);
                // this.uploadImglist.push(res.fileName)
                this.uploadImglist.push({
                    url: res.fileName
                })
            }else{
                 this.$message({
                    message: res.msg,
                    type: 'error'
                });
            }
            this.loading.close();
        },
        handleRemove(file, fileList) {
            this.uploadImglist = []
            fileList.forEach(item=>{
                this.uploadImglist.push(item)
            })
        },
        // 上传失败
        imgerror(){
            this.$message({
                type: "error",
                message:'上传失败'
            })
            this.loading.close();
        },
        // 上传中
        beforeAvatarUpload(file){
            this.loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 < 150;
            
            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式');
            }
            // else if (!isLt2M) {
            //     this.$message.error('上传图片大小不能超过 150kb');
            // }
            this.loading.close();
            return isJPG;
        },
        handleExceed(files, fileList) {
            this.$message.warning(`最多只能上传10张图片`);
        },
        // 判断是否登录
        islogin(){
            // 1为编辑，2为阅读，0为未登录
            const pass = this.$cookies.get('pass')
            let returndata = 0
            if(pass ==  this.readPass || pass == this.editPass){
                if(this.existSystem()){
                    returndata = 1
                }else{
                    returndata = 2
                }
            }else{
                returndata = 0
            }
            return returndata
        },
        
    },
    mounted() {
        let ua = navigator.userAgent,
            isAndroid = /(?:Android)/.test(ua),
            isFireFox = /(?:Firefox)/.test(ua)
        this.isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
        this.loading = this.$loading({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getMaptypeList()//地图类别
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>